<template>
<div id="crear-notificacion">
    <div class="form-crear-notificacion-container">
        <div class="form-crear-notificacion">
            <div class="form-users">
                <p class="title-section">Usuarios:</p>
                <CheckBox class="checkbox" label_name="Todos los usuarios" checkbox_key="key_checkbox_todos_usuarios" checkbox_id="id-checkbox-todos-usuarios" :checkbox_state="true" @_emit_method_checkbox="_getValueFromCheckboxUsers" />
                <div @click="_getValueFromCheckboxUsersType('id-checkbox-todos-usuarios-uid', 'uid')">
                    <CheckBox v-if="!all_users" class="checkbox" label_name="Por UID" checkbox_key="key_checkbox_todos_usuarios_uid" checkbox_id="id-checkbox-todos-usuarios-uid" :checkbox_state="true" />
                </div>
                <div @click="_getValueFromCheckboxUsersType('id-checkbox-todos-usuarios-email', 'email')">
                    <CheckBox v-if="!all_users" class="checkbox" label_name="Por email" checkbox_key="key_checkbox_todos_usuarios_email" checkbox_id="id-checkbox-todos-usuarios-email" :checkbox_state="false" />
                </div>
                <InputTextarea v-if="!all_users" id="input_comment" @_emit_method_order_input_textarea="_getInputValueUsers" ref="ref_users_input" input_key_to_textarea_values="key_users_input" />
            </div>
            <div class="form-rols" v-if="show_rols">
                <p class="title-section">Roles:</p>
                <CheckBox class="checkbox" v-for="(cbox, cboxIndex) in rols_config" :key="cboxIndex" :label_name="cbox.label_name" :checkbox_key="cbox.checkbox_key" :checkbox_id="cbox.checkbox_id" :checkbox_state="cbox.checkbox_state" @_emit_method_checkbox="_getValueFromCheckboxRols" />        
            </div>
            <div class="form-date">
            <p class="title-section">Fecha:</p>
                <CheckBox class="checkbox" label_name="Hoy" checkbox_key="key_checkbox_hoy" checkbox_id="id-checkbox-hoy" :checkbox_state="true" @_emit_method_checkbox="_getValueFromCheckboxDate" />
                <div v-if="!is_for_today">
                    <Datepicker placeholder="Fecha notificación" v-model="datepicker_fecha_notificacion" :language="es" :full-month-name="true" :mondayFirst="true" :clearButton="true" format="dd/MM/yyyy" input-class="datepicker-input" class="inlined datepick" />
                    <div style="display:flex; gap: 15px; padding-top: 10px">
                        <InputTextOutline 
                        class="value-input-hours" 
                        @_emit_method_order_input="_getValueFromInputValueHours"
                        input_label="Horas"  
                        input_helper="Horas" 
                        :has_focus="false"
                        icon_class="value_icon_class_hour" 
                        input_key_to_save_user_values="value_input_key_to_save_hour_value" 
                        input_class_character_counter="value_input_class_character_counter_hour" 
                        :permission="true" 
                        :icon_permission="false" 
                        :character_counter_permission="false" 
                        :helper_permission="false" 
                        :test_field_is_numeric="true" 
                        :length="2"
                        computed_value=""
                        :computed_value_permission="false"
                        :is_input_textarea="false" />
                        <span>:</span>
                        <InputTextOutline 
                        class="value-input-hours" 
                        @_emit_method_order_input="_getValueFromInputValueMinutes"
                        input_label="Minutos"  
                        input_helper="Minutos" 
                        :has_focus="false"
                        icon_class="value_icon_class_minutes" 
                        input_key_to_save_user_values="value_input_key_to_save_minutes_value" 
                        input_class_character_counter="value_input_class_character_counter_minutes" 
                        :permission="true" 
                        :icon_permission="false" 
                        :character_counter_permission="false" 
                        :helper_permission="false" 
                        :test_field_is_numeric="true" 
                        :length="2"
                        computed_value=""
                        :computed_value_permission="false"
                        :is_input_textarea="false" />
                    </div>
                </div>
            </div>
            <div class="form-title">
                <p class="title-section">Titulo:</p>
                <InputTextOutline 
                class="value-input" 
                @_emit_method_order_input="_getValueFromInputValueTitle"
                input_label="Titulo"  
                input_helper="Titulo" 
                :has_focus="false"
                icon_class="value_icon_class" 
                input_key_to_save_user_values="value_input_key_to_save_title_value" 
                input_class_character_counter="value_input_class_character_counter" 
                :permission="true" 
                :icon_permission="false" 
                :character_counter_permission="false" 
                :helper_permission="false" 
                :test_field_is_numeric="false" 
                :length="128"
                computed_value=""
                :computed_value_permission="false"
                :is_input_textarea="false" />
            </div>
            <div class="form-body">
            <p class="title-section">Body:</p>
                <InputTextarea id="input_comment" @_emit_method_order_input_textarea="_getInputValueBody" ref="ref_body_input" input_key_to_textarea_values="key_body_input" input_placeholder="Body" />
            </div>
            <div class="form-body">
            <p class="title-section">Scripts:</p>
                <InputTextarea id="input_comment" @_emit_method_order_input_textarea="_getInputValueScript" ref="ref_script_input" input_key_to_textarea_values="key_scripts_input" input_placeholder="Script" />
            </div>
            <div class="send-form">
                <Button class="create-portfolio" @_emit_method_button="_sendFormToCreateNotification" button_name="Crear notificacion" button_key="button_key_create_notification" />
            </div>
        </div>
    </div>
</div>
</template>



<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import Rols from "@/views/crear_notificacion_configs/rols.json";

import CheckBox from "@/components/helpers/CheckBox";
import InputTextarea from "@/components/helpers/InputTextarea.vue";
import InputTextOutline from "@/components/helpers/InputTextOutline.vue";
import Button from "@/components/helpers/Button";
import Datepicker from 'vuejs-datepicker';
import {en, es} from 'vuejs-datepicker/dist/locale'

export default {
    mixins:[APICaller],
    name: 'CrearNotification',
    components: {
        CheckBox,
        InputTextarea,
        InputTextOutline,
        Datepicker,
        Button
    },
    props: {
    },
    data: () => ({
        rols_config: Rols,
        all_users: true,
        is_for_today: true,
        datepicker_fecha_notificacion: "",
        en: en,
        es: es,
        rols: [],
        timestamp: 0,
        title: "",
        body: "",
        script: "",
        type_user: "uid", //aqui se va a guardar uid si esta en modo uid o email si esta en modo email, segun el caso se llamara a un servicio o a otro
        users_input: "",
        hours: 0,
        minutes: 0,
        show_rols: true,
    }),
    mounted() {
    },
    created: function() {
    },
    watch: {
        datepicker_fecha_notificacion(){
            this._parseDatepickerFormat(this.datepicker_fecha_notificacion.toString())
        }

    },
    computed: {
    },
    methods: {
        _getValueFromCheckboxRols: function(value){
            const keys = {
                key_bronce: "13",
                key_silver: "14",
                key_gold: "15",
                key_platino: "16",
                key_basico_europa: "21",
                key_basico_america: "20",
                key_suspendido: "11",
                key_basico_asiste_avanzado: "24",
                key_pre_eureker: "27"
            }

            const result = keys[value[1]]
            if (this.rols.includes(result)) {
               var index = this.rols.indexOf(result);
                this.rols.splice(index, 1); 
            }else{
                this.rols.push(result)
            }
        },
        _getValueFromCheckboxUsers: function(value){
            this.all_users = value[0];
            if(this.all_users){
                this.users_input = "";
            }
            if(value[0]){
                this.show_rols = true;
            }else{
                this.rols = [];
                this.show_rols = false;
            }
        },
        _getValueFromCheckboxUsersType: function(id, type){
            document.getElementById("id-checkbox-todos-usuarios-uid").checked = false;
            document.getElementById("id-checkbox-todos-usuarios-email").checked = false;
            
            document.getElementById(id).checked = true;
            this.type_user = type;
            this.rols = [];
            this.show_rols = false;
        },
        _getInputValueUsers: function(value){
            this.users_input = value[0]
            this.users_input = this.users_input.split(',').map(function(item) {
                return item.trim();
            });
        },
        _getValueFromCheckboxDate: function(value){
            this.is_for_today = value[0]
        },

        _parseDatepickerFormat: function(date){
            let array = date.split(" ");
            let day = array[2];
            let year = array[3];
            let month = array[1];
            const months = {
                Jan: "01",
                Feb: "02",
                Mar: "03",
                Apr: "04",
                May: "05",
                Jun: "06",
                Jul: "07",
                Aug: "08",
                Sep: "09",
                Oct: "10",
                Nov: "11",
                Dec: "12"
            }
            month = months[month]

            let final_date = day + "/" + month + "/" + year;

            this.timestamp = new Date(final_date.split("/").reverse().join("-") + "T00:00:00+01:00");
            // Return timestamp
            this.timestamp = this.timestamp.getTime()
            
        },

        _getValueFromInputValueTitle: function(value){
            this.title = value[0];
        },
        _getInputValueBody: function(value){
            this.body = value[0];
        },
        _getInputValueScript: function(value){
            this.script = value[0];
        },
        _sendFormToCreateNotification: function(){
            let params = new URLSearchParams();
            params.append("roles", this.rols);
            params.append("users", this.users_input);
            if(this.timestamp == 0){
                this.timestamp = Date.now();
            }
            this.timestamp += (this.hours * 60 *60 * 1000) + (this.minutes * 60 * 1000);
            params.append("timestamp", this.timestamp);
            params.append("title", this.title);
            params.append("body", this.body);
            if(this.all_users){
                params.append("field", "rol");
            }else{
                params.append("field", this.type_user);
            }
            params.append("script", this.script);


            let success = response =>{
                this.$vueOnToast.pop('success',"Notificacion creada con exito");
                setTimeout(function(){ location.reload(); }, 500);
            }

            let url = "/api/v1/eurekers-notifications/handler-admin-notification";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('236', 'Error al crear nueva notificacion desde el panel de admin');

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        _getValueFromInputValueHours: function(value){
            this.hours = parseInt(value[0])
        },
        _getValueFromInputValueMinutes: function(value){
            this.minutes = parseInt(value[0])
        },

    },
}
</script>


<style lang="scss" scoped>
  @import "@/styles/basics/globals.scss";
  #crear-notificacion{
    width: 100%;
    height: 100%;
  }
  #crear-notificacion p, #crear-notificacion span{
    color: var(--color-text-primary);
  }
  .form-crear-notificacion {
    background-color: var(--color-background) !important;
  }
  .form-crear-notificacion-container{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-crear-notificacion{
    background: white;
    height: 90%;
    width: 50%;
    align-self: center;
  }
  .datepicker-input{
    text-align:  center;
    font-size: 11px;
    margin-top: 10px;
    width:10px;
    height: 38px;
    border-radius: 5px;
    border-color: #aaadb7;
    margin-left: 8px;
  }
  .form-rols{
      padding: 10px 0 10px 0;
  }
  .form-users{
      padding: 10px 0 10px 0;
  }
  .form-date{
      padding: 10px 0 10px 0;
  }
  .form-title{
      padding: 10px 0 10px 0;
  }
  .form-body{
      padding: 10px 0 10px 0;
  }
  .title-section{
      font-size: 20px;
      font-weight: bold;
  }
  .value-input-hours{
    width: 10%;
  }
</style>